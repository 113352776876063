import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  TextInput,
  SelectField,
} from "react-admin";
import { Authorizer } from "../../common/Authorizer";
import CDeleteButton from "../../common/CDeleteButton";
import { DisplayDateTime } from "../../common/DisplayDate";
const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput
      variant="outlined"
      label="User Name"
      source="userName~like"
      alwaysOn
    />
  </Filter>
);
export const UsersList = (props) => {
  let propsObj = Authorizer(props,"List");  
  //propsObj.hasCreate=false;
  return (
    <List {...propsObj} title='User List'  exporter={false}  bulkActionButtons={false}    filters={<Filters />} 
    // filter={ { 'roleTypeId~eq': 8 } } 

     >
     <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        {/* {propsObj.hasDelete && <CDeleteButton {...props}/>} */}
        <TextField source="code" label="User Id" />
        <TextField source="userName" label="User Name " />
        <TextField source="name" label="Name" />
        <TextField source="email" label="Email " />
        <TextField source="mobileNo" label="Mobile No" />
        <ReferenceField source="reportTo" reference="users" label="Reporting To"  link={false}>
                <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isActive" label="Active"/>
        
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        
        <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Modified By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
