import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  Filter,
  SearchInput,ReferenceInput,AutocompleteInput
   
} from "react-admin";
import { useHistory } from "react-router-dom"; // Import useHistory for navigation
import { DisplayDateTime } from "../../common/DisplayDate";
import { Authorizer } from "../../common/Authorizer";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit"; // Import the Edit icon

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="Subject Line" source="subjectLine~like" alwaysOn />
    <ReferenceInput perPage={25} variant="outlined" label="Candidate" source="candidateId" reference="candidates" fullWidth={true} sort={{ field: 'name', order: 'ASC' }} allowEmpty  filterToQuery={searchText => ({ 'name~like': searchText })}>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val?.trim().length >= 1 }} />
    </ReferenceInput>
  </Filter>
);

const CustomEditButton = (props) => {
  const history = useHistory();
  let { record } = props;

  const handleEdit = () => {
    // Navigate to the edit page with the reqId
    history.push(`/submissions/${record.reqId}/5`);
  };

  return (
    <Button onClick={handleEdit}   color="primary" startIcon={<EditIcon />}>
    Edit
  </Button>
  );
};

export const ActivityList = (props) => {
  const propsObj = Authorizer(props, "List");
  
  return (
    <List {...propsObj} exporter={false} bulkActionButtons={false} filters={<Filters />}>
      <Datagrid rowClick={false}>
        {propsObj.hasEdit && <CustomEditButton label="Action" source="id"/>}
        <TextField source="requisitionNo" label="Requisition No" />
        <TextField source="subjectLine" label="Subject Line" />
        
        <ReferenceField source="clientId" reference="clients" label="Client" link={false}>
          <TextField source="name" />
        </ReferenceField>
        
        <ReferenceField source="candidateId" reference="candidates" label="Candidate" link={false}>
          <TextField source="name" />
        </ReferenceField>
        
        <BooleanField source="isActive" label="Active" />
        
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        
        <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
        
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        
        <DisplayDateTime source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
