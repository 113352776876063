import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField,
  DateInput,
  AutocompleteInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../common/formStyles";
import { Authorizer } from "../../common/Authorizer";
import RichTextInput from 'ra-input-rich-text';
export const ActivityCreate = (props) => {
  let propsObj = Authorizer(props, "Create");
  const initialValues = {
    isActive: true,
    activeDate: new Date()

  }
  const classes = useStyles({});
  return (
    <Create  {...propsObj}>
      <SimpleForm initialValues={initialValues} toolbar={<FormToolbar {...props} />} variant="outlined">
      <ReferenceInput label="Client" source="clientId"
          reference="clients"
          validate={required()} sort={{ field: 'name', order: 'asc' }} filter={{ isActive: 1 }} fullWidth={true}   >
          <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>
        <DateInput source="activeDate" fullWidth={true} label="Active Date" validate={[required()]} formClassName={classes.first_inline_input} />
        <TextInput source="subjectLine" fullWidth={true} label="Subject Line" validate={[required()]} formClassName={classes.last_inline_input} />

        <ReferenceInput perPage={25} sort={{ field: 'Name', order: 'ASC' }}
          filterToQuery={searchText => ({ 'name~like': searchText })} variant="outlined"
          label="Location City" source="locationCityId" reference="cities" fullWidth={true} formClassName={classes.one_three_input}  >
          <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>
        <BooleanInput source="isLocalOnly" validate={[required()]} initialValue={true} label="Is Local Only" formClassName={classes.two_three_input} />
        <ReferenceInput label="Employment Type" source="employmentTypeId"
          reference="lookups"
          validate={required()} sort={{ field: 'name', order: 'asc' }} filter={{ type: 'EMPTP' }} fullWidth={true} formClassName={classes.last_three_input}  >
          <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>
        <RichTextInput fullWidth={true} validate={required()} source="jobDetails" label="Job Details" toolbar={[[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['image', 'link', 'video'], ['clean']]} formClassName={classes.first_inline_input} />
        <RichTextInput fullWidth={true} validate={required()} source="skilDetails" label="Skill Details" toolbar={[[{ 'header': [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['image', 'link', 'video'], ['clean']]} formClassName={classes.last_inline_input} />
        <ReferenceInput perPage={25} sort={{ field: 'Name', order: 'ASC' }}
          filterToQuery={searchText => ({ 'name~like': searchText })} variant="outlined"
          label="Workauthorization" source="workAuthorizationId" reference="workauthorizations" fullWidth={true} formClassName={classes.first_inline_input}  >
          <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>

        <ReferenceInput perPage={25} sort={{ field: 'userName', order: 'ASC' }}
          filterToQuery={searchText => ({ 'userName~like': searchText })} variant="outlined"
          label="Assigned By" source="assignedBy" reference="users" fullWidth={true} formClassName={classes.last_inline_input}  >
          <AutocompleteInput variant="outlined" optionText="userName" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>

        <ReferenceInput label="Status" source="statusId"
          reference="lookups"
          validate={required()} sort={{ field: 'name', order: 'asc' }} filter={{ type: 'REQUISSTATUS' }} fullWidth={true} formClassName={classes.last_three_input}  >
          <AutocompleteInput variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>
        
        



      </SimpleForm>
    </Create>
  );
};
